import React from 'react'
import '../assets/css/teachers-list-item.css'

const TeachersListItem = props => {
    return (
        <div className="teachers-list-item" onClick={props.click}>
            <h1>{props.title}</h1>
        </div>
    )
}

export default TeachersListItem