import React from 'react'
import '../assets/css/admin-back-arrow.css'
import arrow from '../assets/images/left-arrow.svg'
import { useHistory } from 'react-router-dom'

const BackArrow = props => {
    const history = useHistory()
    return (
        <>
            <img onClick={props.click} className="arrow-bottom" src={arrow} alt="" />
        </>
    )
}

export default BackArrow