import React from 'react'
import { useDispatch } from 'react-redux'
import '../assets/css/menu-user.css'

const MenuUser = props => {
    const dispatch = useDispatch()
    return (
        <div className="menu-profile">
            <div className="m-p-buttons" onClick={() => {
                dispatch({
                    type: "CLEAN_STATE"
                })
            }}>
                Cerrar sesión
            </div>
        </div>
    )
}

export default MenuUser;