import React, { useState } from 'react'
import '../assets/css/login-input.css'

import User from '../assets/images/user.jsx'
import Lock from '../assets/images/lock.jsx'

import { shake } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

const styles = {
	shake: {
		animation: 'x 0.9s ease 0s infinite',
		animationName: Radium.keyframes(shake, 'shake')
	}
}

const LoginInputs = (props) => {
    // State

    const [onFocus, onFocusSeter] = useState(false);

    // Handler

    const focusHandle = () => {
        onFocusSeter(true)
    }
    const blurHandle = () => {
        onFocusSeter(false)
    }
    const changeHandle = e => {
        props.change(e)
    }

    // Utils
    return (
        <StyleRoot>
            <div className={`login-input ${onFocus ? 'border-focus' : ''}`}>
                <div className="l-i-icon">
                    {
                        props.user ?
                            <User fill={onFocus ? "#79CEAE" : "rgba(121, 206, 173, 0.329)"} />
                        :
                            <Lock fill={onFocus ? "#79CEAE" : "rgba(121, 206, 173, 0.329)"} />
                    }
                </div>
                <input style={props.shake ? styles.shake : {}} type={props.ocult ? "password" : "text"} value={props.value} onChange={changeHandle} placeholder={props.place} onBlur={blurHandle} onFocus={focusHandle} />
                {/* <input style={styles.shake} type={props.ocult ? "password" : "text"} value={props.value} onChange={changeHandle} placeholder={props.place} onBlur={blurHandle} onFocus={focusHandle} /> */}
            </div>
        </StyleRoot>
    )
}

export default LoginInputs