import React, { Component } from 'react'
import { Container, Row } from 'reactstrap'
import { connect } from 'react-redux'
import '../assets/css/alumn-view.css'

import Sections from '../components/SectionTabs'
import AlumnTable from '../containers/TableAlumn'

class AlumnView extends Component {
    state = {
        data: {
            name: "",
            section: "",
            homeworks: [],
            section: "",
            real_name: ""
        }
    }
    seter = (d) => {
        if (d) {
            this.setState({
                data: d
            })
        }
    }
    render() {
        const actualSection = this.props.actualSection
        if (actualSection.id !== this.state.data.id) {
            this.setState({data: actualSection})
        }

        const role = this.props.role
        return (
            <>
                <Container className="container-d-views" fluid>
                    <Row className="a-v-header">
                        {
                            (role === "teacher") ?
                                <h1>Tus asignaciones</h1>
                            :
                                <h1 style={{fontSize: 34}}>{`Alumnos de ${this.props.teacher_name}`}</h1>
                        }
                    </Row>
                    <Row className="d-flex flex-column">
                        <Sections 
                            seter={this.seter} 
                            actual={this.state.data.real_name} 
                            data={this.props.data} 
                        />
                        <AlumnTable 
                            actual={this.state.data} 
                            alumns={this.state.data.alumns} 
                            data={this.state.data} 
                        />
                    </Row>
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => ({ 
    studentsFromSections: state.students.values, 
    section: state.sections.values,
    homeworks: state.homeworks.values,
    role: state.users.data.user_role,
    teacher_name: state.layout.teacher_name,
    actualSection: state.layout.data.section_selected
})

export default connect(mapStateToProps)(AlumnView);