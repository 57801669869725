import React, { Component } from 'react';
import '../assets/css/table-task.css'

const TaskItem = ({ data }) => {
        return (
            <>
                <div className="i-t-content">
                    {
                        data.map((item, key) => {
                            if (item === null) {
                                return (
                                    <div
                                        key={key}
                                        className="i-t-c-item-no-value"
                                    >
                                        <span>Tarea no asignada</span>
                                    </div>
                                )
                            }
                            return (
                                <div
                                    key={key}
                                    style={{
                                        color: (item > 65) ? '#00a36c' : '#fb6e5b'
                                    }}
                                    className="i-t-c-item"
                                >
                                    {item}
                                </div>
                            )
                        })
                    }
                </div>
            </>
        )
    }


export default TaskItem