import React from 'react'
import '../assets/css/button-login.css'

// import error from '../assets/images/cancel.svg'

const ButtonLogin = (props) => {
    return(
        <>
            <button onClick={props.click} className={`button-login ${props.charging ? "bl-dis" : "bl-normal"}`}>
                {
                    props.charging ?
                        <div className="loader_sprite"></div>
                    :
                        "ENTRAR"
                }
            </button>
        </>
    )
}

export default ButtonLogin