import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../assets/css/buttonNav.css'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

class ButtonNav extends Component {
    baseReco = () => {
        if (this.props.user) {
			if (this.props.user.admin) return 'admin/teacher'
			if (this.props.user.coordinator) return 'coordinador/profesores'
            if (!this.props.user.coordinator && !this.props.user.admin) return 'dashboard'       
        }
    }
    render() {
        const current = this.props.location.pathname.split('/').slice(-1)[0];
        const paths = this.props.location.pathname.split('/')
        const result = paths.find(item => {
            return current == item
        })

        let highlight = false
        paths.map(path => {
            this.props.data.map(item => {
                if (path == item.toLowerCase()) {
                    highlight = true
                }
            });
        });

        return (
            <div className="d-flex justify-content-between align-items button-nav">
                {
                    this.props.data.map((item, key) => {
                        return (
                            <Link key={key} to={`${this.props.baseUrl}/${item.toLowerCase()}`}>
                                <button className={current == item.toLowerCase() ? `button-nav-ac` : `button-nav-inac`} >
                                    {
                                        item.charAt(0).toUpperCase() + item.slice(1)
                                    }
                                </button>
                            </Link>
                        )
                    })
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default   withRouter(connect(mapStateToProps)(ButtonNav));