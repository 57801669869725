import initialState from './initialState'
import { parserJsonFirebase, resultParser } from './utils/firebaseUtils'
import { recalculateResultsHw } from './utils/utilsFunctions'

import PUSH_SECTION from './reducerCase/PUSH_SECTION'
import PUSH_TEACHER from './reducerCase/PUSH_TEACHER'

export default (state = {}, action) => {
    switch (action.type) {
        case 'PUSH_SECTION':
            return PUSH_SECTION(state, action)
        case 'PUSH_TEACHER':
            return PUSH_TEACHER(state, action)
        case "SELECT_SECTION":
            return {...state, layout: { ...state.layout, data: { ...state.layout.data, section_selected: action.payload } }}
        case 'LOGOUT': 
            return {...initialState}
        case 'RES':
            const homeworks = {...state.homeworks, values: action.payload.homeworks}
            const teachers = {...state.teachers, values: action.payload.teachers }
            const students = {...state.students, values: action.payload.students }
            const sections = {...state.sections, values: action.payload.sections }
            const schools = {...state.schools, values: action.payload.schools }
            return {...state, homeworks, teachers, students, sections, schools, date: new Date()}
        case "MODAL_SWITCH":
            let layout = {...state.layout}
            layout.data.modals.homework_view.open = action.payload.status
            layout.data.modals.homework_view.data = {
                count: action.payload.count,
                results: action.payload.results,
                title: action.payload.title
            }
            return {...state, layout, date: new Date()}
        case "LOGIN_USER":
            // const user = state.user.data = action.payload
            let reservedOne = {
                "admin": false,
                "teacher": false,
                "coordinator": false
            }
            reservedOne[action.payload.user_role] = true
            return {
                ...state, 
                users: {
                    ...state.users, 
                    data: {
                        // ...state.users.data,
                        ...action.payload
                    }
                }, 
                layout: {
                    ...state.layout,
                    data: {
                        ...state.layout.data,
                        user_type: {
                            ...reservedOne,
                        },
                        auth: true
                    }
                }
            }
            // return {...state,}
        case "GEN_LOAD":
            const value = action.payload
            return {
                ...state, 
                layout: {
                    ...state.layout, 
                    data: {
                        ...state.layout.data, 
                        navigation_button: true,
                        loading: value
                    }
                },
                date: new Date()
            }
        case "CLEAN_DATA_STATE":
            const initualSelected = {
                "id": "",
                "name": "",
                "section": "",
                "homeworks": [],
                "real_name": "",
                "section": ""
            }
            return {
                ...state, 
                layout: {...state.layout, data: {...state.layout.data, section_selected: initualSelected }},
                homeworks: {...state.homeworks, values: [] },
                teachers: {...state.teachers, values: [] },
                students: {...state.students, values: [] },
                sections: {...state.sections, values: [] },
                schools: {...state.schools, values: [] }
            }
        case "SET_NAME":
            return {...state, 
                layout: {
                    ...state.layout,
                    teacher_name: action.payload
                }
            }
        case "CLEAN_STATE":
            return initialState
        case "UPDATE_HOMEWORK_RESULTS":
            const studentId = action.payload.info.student_id
            // let actualHomeworks = state.homeworks.values
            let fullHwStore = state.homeworks.values
            let allHomeworks = state.homeworks.values.find(item => item.sectionId === action.payload.info.section_id)
            const hw = allHomeworks.homeworks.find(item => item.id === action.payload.info.homework_id)
            const parsed = parserJsonFirebase(action.payload.result.block_info)
            let blocks = hw.blocks

            let acum = []

            for (let i in parsed) {
                const item = parsed[i]
                let updating = blocks.find(el => el.descriptor === item.block_descriptor_id)
                const itemTranformed = resultParser(item, action.payload.info.student_id)
                let resultUpdated = updating.results.find(r => r.student_id === studentId)
                resultUpdated = itemTranformed

                updating.results = updating.results.map(up => {
                    if (updating.descriptor === item.block_descriptor_id) {
                        const inmmutableUp = up
                        if (up.student_id === resultUpdated.student_id) return resultUpdated
                        return inmmutableUp
                    } else {
                        return up
                    }
                })
                acum.push(updating)
            }
            // console.log(allHomeworks)
            allHomeworks.homeworks = allHomeworks.homeworks.map(hw1 => {
                // console.log(hw1.id,hw.id)
                if (hw1.id === hw.id) return hw
                return hw1
            })
            recalculateResultsHw(hw)
            fullHwStore = fullHwStore.map(a => {
                if (a.sectionId === allHomeworks.id) return allHomeworks
                return a
            })
            // Calculate new prm
            return { ...state, homeworks: { ...state.homeworks, values: fullHwStore } }
        default:
            return state
    }
}