import React, { Component } from 'react'
import { CSVLink } from "react-csv";
import '../assets/css/table-alumn.css'
import removeAcc from 'remove-accents'
import dragscroll from 'dragscroll/dragscroll'

import AlumnItem from '../components/AlumnItem'
import HwItem from '../components/HwItem'
import ValueItem from '../components/ValueItem'

import arrowSvg from '../assets/images/arrow-point-to-right.svg'
import FilterItem from '../components/FilterComponent'

import CSVFormat from '../utils/CSVParser'

class Table extends Component {
    constructor(props) {
        super(props)
        this.scroller = React.createRef();
        this.scroller2 = React.createRef();
        this.scrolling = React.createRef();
        this.state = {
            nameBool: null,
            lastnameBool: null,
            prmBool: null,
            order: null
        }
    }
    move = e => {
        this.scrolling.current.scrollLeft = this.scroller.current.scrollLeft
        this.scroller2.current.scrollLeft = this.scroller.current.scrollLeft
    }
    twoWayMove = e => {
        this.scroller.current.scrollLeft = this.scrolling.current.scrollLeft
        this.scroller2.current.scrollLeft = this.scrolling.current.scrollLeft
    }
    getOrder = (n) => {
        const results = []
        for (var i in n) {
            results.push( this.props.alumns.find( alumn => alumn.id === n[i].student_id ) )
        }
        this.setState({
            order: results
        })
    }
    filter = (compare, one, two) => {
        if (compare === null) {
            return null
        } else if (compare) {
            return one()
        } else {
            return two()
        }
    }
    render() {
        const alumns = this.props.alumns || []
        const tasks = this.props.data.homeworks.sort((a,b) => {
            return new Date(b.expire)-new Date(a.expire)
        })
        
        let alumnsF = [...alumns]

        alumnsF = this.filter(this.state.prmBool, () => alumns.sort((a,b) => b.prm-a.prm),() => alumns.sort((a,b) => a.prm-b.prm)) || alumns

        alumnsF = this.filter(this.state.nameBool,() => alumns.sort((a,b) => a.firstname.localeCompare(b.firstname)),() => alumns.sort((a,b) => b.firstname.localeCompare(a.firstname))) || alumns

        alumnsF = this.filter(this.state.lastnameBool,() => alumns.sort((a,b) => a.lastname.localeCompare(b.lastname)),() => alumns.sort((a,b) => b.lastname.localeCompare(a.lastname))) || alumns

        alumnsF = this.state.order || alumnsF

        if (this.state.order) {
            let acum = []
            this.state.order.map((item) => {
                alumnsF.map((alumn) => {
                    if (alumn.id === item) {
                        acum.push(alumn)
                    }
                })
            })
            alumnsF = acum[0] ? acum : alumnsF
        }

        const value = alumnsF.map(alumn => {
            let values = []
            for (let hw of tasks) {
                const isFound = hw.results.find(result => result.student_id === alumn.id)
                if (isFound) {
                    values.push(isFound.score)
                } else {
                    values.push(null)
                }
            }
            return values
        })
        const percent = alumnsF.map((alumn, key) => {
            const results = value[key]
            const success = results.filter(item => item).length
            const total = results.length
            return {
                total,
                success
            }
        })
        
        const alumnsNames = alumnsF.map(item => {
            return `${item.firstname} ${item.lastname}`
        })
        const taskNames = tasks.map(item => {
            return `${item.name}`
        })

        const CSV = CSVFormat.toCSVFormat(alumnsNames, taskNames, value)
        return(
            <>
                <div className="table-task">
                    <div className="t-t-header">
                        <div className="t-t-h-nav">
                            <CSVLink filename={`${this.props.actual.real_name}.csv`} data={CSV}>
                                <button className="t-t-h-n-button">
                                    Descargar notas
                                </button>
                            </CSVLink>
                        </div>
                        <div className="t-t-h-options" onScroll={this.twoWayMove} ref={this.scrolling}>
                            {
                                tasks.map((val, key) => {
                                    return <HwItem
                                        key={key}
                                        data={val}
                                    />
                                })
                            }
                        </div>
                    </div>
                    <div className="t-t-filters">
                        <div className="t-t-f-alumns">
                            <div className="t-t-f-a-item">
                                <span>Nombre</span>
                                <img onClick={() => this.setState({ order: null, prmBool: null, lastnameBool: null, nameBool: !this.state.nameBool })} className={this.state.nameBool ? "t-t-f-a-item-reverse" : ""} src={arrowSvg} alt="" />
                            </div>
                            <div className="t-t-f-a-item">
                                <span>Apellido</span>
                                <img onClick={() => this.setState({ order: null, nameBool: null, prmBool: null, lastnameBool: !this.state.lastnameBool })} className={this.state.lastnameBool ? "t-t-f-a-item-reverse" : ""} src={arrowSvg} alt="" />
                            </div>
                            <div className="t-t-f-a-item">
                                <span>PRM</span>
                                <img onClick={() => this.setState({ order: null, nameBool: null, lastnameBool: null, prmBool: !this.state.prmBool })} className={this.state.prmBool ? "t-t-f-a-item-reverse" : ""} src={arrowSvg} alt="" />
                            </div>                         
                        </div>
                        <div className="t-t-f-values" ref={this.scroller2}>
                            {
                                tasks.map((item, key) => {
                                    return <FilterItem data={item} compare={this.state.order} action={this.getOrder} key={key}/>
                                })
                            }
                        </div>
                    </div>
                    <div className="t-t-content dragscroll">
                        <div className="t-t-c-alumns">
                            {
                                alumnsF.map((alumn, key) => {
                                    return <AlumnItem
                                        data={alumn}
                                        percent={percent[key]}
                                        key={key}
                                    />
                                })
                            }
                        </div>
                        <div className="t-t-c-results dragscroll" onScroll={this.move} ref={this.scroller}>
                            {
                                value.map((item, key) => {
                                    return <ValueItem
                                        data={item}
                                        key={key}
                                    />
                                })
                            }
                        </div>
                    </div>
                </div>
            </>
        )
        
    }
}


export default Table
