import React from 'react'
import '../assets/css/info-card-assignament.css'
import Item from '../components/InfoAlumnItem'

import cancel from '../assets/images/delete.svg'

const InfoCardAssignament = props => {
    return (
        <>
            <div className={`info-card-assignament ${props.full ? 'i-c-a-full' : ''}`}>
                <div className="i-c-a-header">
                    {
                        props.circle ?
                            <div className="d-flex justify-content-around">
                                <span className="i-c-a-h-span">{`${props.circle.count}`}</span>
                            </div>
                        :
                            props.mode ? <div></div> : ''
                    }
                    <h1>
                        {
                            props.title
                        }
                    </h1>
                    {
                        props.closeAction ?
                            <img className="i-c-a-icon" onClick={props.closeAction} src={cancel} alt="" />
                        :
                            props.emuleClose ?
                                <div></div>
                            :
                                ''
                    }
                    {
                        props.prm ? 
                            <span className="i-c-a-h-r-span">{`${props.prm}%`}</span>
                        :
                            null
                    }
                </div>
                <div className="i-c-a-content">
                    {
                        props.data.map((item, key) => {
                            return <Item {...item} key={key}/>
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default InfoCardAssignament