const finder = (value) => (iterationValue) => iterationValue === value

export const agroupArr = (array, div) => {
    let temp = [],
        r = [];
    for (let i in array) {
        const item = array[i]
        if (temp.length < div) {
            temp.push(item)
        } else {
            r.push(temp)
            temp = [item]
        }
    }
    if (temp.length !== 0) r.push(temp)
    return r
}
export const getSize = (sizes, text, div) => {
    const items = text.split("")
    const groups = agroupArr(items, div)
    const size = sizes[groups.length - 1]
    const result = size ? size : sizes[sizes.length - 1]
    return result
}
export const callbackFirebaseListenerSHI = snapshot => {
    console.log(snapshot.val())
}
export const recalculateBlocksPrm = block => {
    let newBlock = block
    let acum = 0
    for (let n in newBlock.results) {
        acum += newBlock.results[n]
    }
    newBlock.prm = parseInt(acum / newBlock.results.length || 1)
    return block
}
export const recalculateAlumnPrm = (alumnsId, blocks) => {
    let acum = 0,
        div = 0;
    for (let i in blocks) {
        const block = blocks[i]
        const result = block.results.find(r => r.student_id === alumnsId)
        if (result) {
            ++div
            acum += result.score
        } else {
            return null
        }
    }
    const result = parseInt(acum / (div || 1));
    return result
}
export const recalculateResultsHw = hw => {
    let newHw = {...hw}
    let result = newHw.results.map(item => {
        const studentId = item.student_id
        const prm = recalculateAlumnPrm(studentId, hw.blocks)
        if (prm) {
            item.score = prm
            return item
        } else {
            return null
        }
    })
    return newHw
}
export const completedHomeworkFromAlumn = (alumnId, hw) => {
    console.error("completedHomeworkFromAlumn is deprecated. Use getCompleteHwsFromStudent.")
    let bool = false
    for (let i in hw.blocks) {
        const item = hw.blocks[i]
        const result = item.results.find(r => r.student_id === alumnId)
        if (result) {
            bool = result.complete
        }
    }
    return bool
}
export const getBlocks = (hws) => {
    let blocks = []
    for (let i in hws) {
        const item = hws[i]
        blocks.push(...item.blocks)
    }
    return blocks
}
export const existSection = (sectionId, allSection) => {
    let result = false
    for (let i in allSection) {
        const item = allSection[i]
        if (item.id === sectionId) result = true
    }
    return result
}
export const getAlumnPrmFromHwInState = (homeworks, studentId, rounded = true) => {
    let totalScores = 0
    for (let homework of homeworks) {
        const isFound = homework.results.find(result => result.student_id === studentId)
        if (isFound) totalScores += isFound.score
    }
    const prm = totalScores / (homeworks.length || 1)
    if (rounded) return parseInt(prm)
    return prm
}
export const getHightAndLessHwsPerStudent = (homeworks, studentId, limit = 70) => {
    let scoreLess = []
    let scoreHeight = []
    for (let homework of homeworks) {
        const isFound = (homework.results.find(result => result.student_id === studentId))
        if (isFound) {
            if (isFound.score >= limit) {
                scoreHeight.push(homework)
            } else {
                scoreLess.push(homework)
            }
        }
    }
    return {
        scoreLess,
        scoreHeight
    }
}
export const getCompleteHwsFromStudent = (homeworks, studentId) => {
    let hwsCompleted = []
    for (let homework of homeworks) {
        const isFound = homework.results.find(result => result.student_id === studentId)
        if (isFound) {
            if (isFound.completed) {
                hwsCompleted.push(homework)
            }
        }
    }
    return hwsCompleted
}
export const getAllBlocksFromHomeworks = (homeworks) => {
    let allBlocks = []
    for (let homework of homeworks) {
        allBlocks = [ ...allBlocks, ...homework.blocks ]
    }
    return allBlocks
}
export const getHightAndLessBlocksPerStudent = (blocks, studentId, limit = 70) => {
    let scoreLess = []
    let scoreHeight = []
    for (let block of blocks) {
        const isFound = block.results.find(result => result.student_id === studentId)
        if (isFound) {
            if (isFound.score >= limit) {
                scoreHeight.push(block)
            } else {
                scoreLess.push(block)
            }
        }
    }
    return {
        scoreLess,
        scoreHeight
    }
}
export const orderItemsByMonths = (items = [], key = "") => {
    const orderedItems = [
        [],
        [],
        [],
        [],

        [],
        [],
        [],
        [],

        [],
        [],
        [],
        []      
    ];
    try {
        for (let item of items) {
            const month = new Date(item[key]).getMonth()
            orderedItems[month].push(item)
        }
    } catch(e) {
        console.error(e)
        return []
    }
    return orderedItems
}
export const orderByDate = (items = [], key = "", inverted = false) => {
    try {
        return items.sort((a,b) => {
            const dateA = new Date(a[key])
            const dateB = new Date(b[key])

            return inverted ? (dateA-dateB) : (dateB-dateA)
        })
    } catch(e) {
        console.error(e)
        return []
    }
}
export const duplicateValuePerKey = (key, items) => {
    let values = []
    for (let item of items) {
        const value = item[key]
        const isFound = values.find((verify) => verify === value)
        if (isFound) return true
        values.push(value)
    }
    return false
}
export const existValueInItems = (key, value, items) => {
    for (let item of items) {
        if (item[key] === value) return true
    }
    return false
}
export const getValuesFromKeys = (key, items, spread = false) => {
    let values = []
    for (let item of items) {
        spread ? values.push(...item[key]) : values.push(item[key])
    }
    return values
}
export const agroupByKey = (byKey, arrayOfItems) => {
    const memoryOfKeys = []
    const agrupedData = []

    for (let item of arrayOfItems) {
        const key = item[byKey]
        const foundIndex =  memoryOfKeys.findIndex(finder(key))
        if (foundIndex >= 0) {
            agrupedData[foundIndex].push(item)
        } else {
            memoryOfKeys.push(key)
            agrupedData.push([item])
        }
    }

    return agrupedData
}
export const prmFromResults = (results, rounded) => {
    const len = results.length
    let scores = 0
    for (let result of results) {
        scores += result.score
    }
    return rounded ? Math.round(scores / (len || 1)) : scores / (len || 1)
}