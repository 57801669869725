import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import '../assets/css/teachers-list.css'
import Item from '../components/TeachersListItem'

import { sections_data as section_data } from '../utils/api/firebase_section_api'

import firebaseToStore from '../utils/firebaseToStore'
import useLoading from '../hooks/useLoading'
import useOnceData from "../hooks/useOnceData"

const TeachersList = props => {
    const dispatch = useDispatch()
    const deployData = useOnceData()
    
    const { push } = useHistory()
    const loading = useLoading()
    const redirectTo = () => {
        push("/coordinador/profesores/alumnos")
    }

    return (
        <div className="teachers-list">
            {
                props.data.map((item, key) => {
                    return <Item click={() => {
                        if (item.firebase) {
                            dispatch({
                                type: 'GEN_LOAD',
                                id: "teacher_list 33",
                                payload: true
                            })
                            loading(true, "")
                            section_data(item.teacher_id)
                                .then(async result => {
                                    const data = await firebaseToStore(result, item.teacher_id)
                                    dispatch({
                                        type: "CLEAN_DATA_STATE"
                                    })
                                    dispatch({
                                        type: "RES",
                                        id: "teacher list",
                                        payload: data
                                    })
                                    dispatch({
                                        type: 'GEN_LOAD',
                                        id: "teacher_list 47",
                                        payload: false
                                    })
                                    dispatch({
                                        type: 'SET_NAME',
                                        payload: `${item.firstname} ${item.lastname}`
                                    })
                                    redirectTo()
                                    loading(false)
                                })
                                .catch(err => {
                                    dispatch({
                                        type: 'GEN_LOAD',
                                        id: "teacher_list 60",
                                        payload: false
                                    })
                                    loading(false)
                                })
                        } else {
                            item.user_role = "teacher"
                            dispatch({
                                type: 'SET_NAME',
                                payload: `${item.firstname} ${item.lastname}`
                            })
                            loading(true)
                            redirectTo()
                            deployData(item, item.teacher_id)
                        }
                    }} title={`${item.firstname} ${item.lastname}`} key={key} />
                })
            }
        </div>
    )
}

export default TeachersList