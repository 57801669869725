export default (state, action) => {
    return {
        ...state,
        teachers: {
            ...state.teachers,
            values: [
                ...state.teachers.values,
                ...action.payload
            ]
        }
    }
}