import React from 'react'
import Teachers from '../components/TeachersList'
import '../assets/css/teachers-list-view.css'

const TeachersListView = props => {
    return (
        <div className="teachers-list-view">
            <Teachers data={props.teachers} />
        </div>
    )
}

export default TeachersListView