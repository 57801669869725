import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { HashRouter as Router } from 'react-router-dom'
import reducer from './reducer'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import initialState from './initialState'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
    key: 'HiKrKiNMivS5ynHiKr',
    storage,
    stateReconciler: autoMergeLevel2
}
const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

console.log("v01/04/2020")

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistStore(store)}>
            <Router >
                <App />
            </Router>
        </PersistGate>
    </Provider>, 
    document.getElementById('root'));

serviceWorker.unregister()
