import React from 'react'
import Table from '../components/LayoutListSchools'
import { Container, Row, Col } from 'reactstrap'
import Searcher from '../components/Searcher'

const TableSchools = props => {

    let data = props.data
    return (
        <Container>
            {/* <Searcher action={n => console.log(n)} data={[ {name: "edisson"}, {name: "mata"} ]} /> */}
            <Row className="mt-5">
                <Table data={data} />
            </Row>
        </Container>
    )
}

export default TableSchools