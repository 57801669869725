import React, { useState } from 'react'
import '../assets/css/filter-comp.css'

import arrowSvg from '../assets/images/arrow-point-to-right.svg'

const FilterComponent = (props) => {
    // State

    const [direction, setDirection] = useState(false);

    const handlerAction = () => {
        setDirection(!direction)
        const newOrd = props.data.results.sort((a,b) => direction ? a.score-b.score : b.score-a.score )
        props.action(newOrd)
    }
    return (
        <>
            <div className="filter-comp">
                <img src={arrowSvg} alt="" onClick={handlerAction} />
            </div>
        </>
    )
}

export default FilterComponent