import React, { useState } from 'react'
import '../assets/css/school-item.css'

import { Collapse } from 'reactstrap';
import icon from '../assets/images/school.svg'

import Teacher from '../components/TeachersTable'

const SchoolItem = props => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div className="school-item">
                <div className="s-i-first">
                    <img 
                        src={icon}
                        alt=""
                    />
                    <h1>{props.data.name}</h1>
                </div>
                <div className="s-i-second">
                    {""}
                </div>
                <div className="s-i-last">
                    <button onClick={() => {
                        setOpen(!open)
                    }} className={open ? 'press' : false ? 'press' : ''}>
                        Maestros
                    </button>
                </div>
            </div>
            <Collapse className="w-auto-d" isOpen={open}>
                {
                    props.data.teachers.map((item, key) => {
                        return <Teacher data={item} firebase={props.data.firebase} key={key} />
                    })
                }
            </Collapse>
        </>
    )
}

export default SchoolItem


