import React from 'react'
import '../assets/css/popover-menu-button.css'

const PopoverMenuButton = props => {
    return (
        <div className="popover-menu-button" onClick={props.click}>
            {props.title}
        </div>
    )
}

export default PopoverMenuButton