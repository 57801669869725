import React from 'react'
import '../assets/css/menu-popover.css'

const MenuPopover = props => {
    return (
        <div className="menu-popover-g">
            {props.children}
        </div>
    )
}

export default MenuPopover