const agroupItems = n => {
    let acum = []
    n.map(y => {
        y.map((item, index) => {
            if (acum[index]) {
                acum[index] = [...acum[index], item]
            } else {
                acum[index] = [item]
            }
        })
    });
    return acum
}

const toCSVFormat = (alumnos, tareas, results) => {
    let acum = []
    acum.push(["alumnos", ...tareas])
    results.map((result, pos) => {
        acum.push([alumnos[pos], ...result]);
    })
    return acum
}

export default {
    agroupItems,
    toCSVFormat
}