import React from 'react'
import { useSelector } from 'react-redux'
import { Row } from 'reactstrap'

import Buttons from '../components/ButtonNav'
import ProfileNav from '../components/ProfileNav'

import '../assets/css/navbar.css'
import Test from '../components/BurguerButton'

const Navbar = props => {
    const permission = useSelector(state => state.layout.data.navigation_button)
    let comp
    if (!props.dis && window.screen.availWidth > 500) {
        comp = <Buttons baseUrl={props.baseUrl} data={props.buttons || []} />
    } else if (!props.dis) {
        if (permission) {
            comp = <Test baseUrl={props.baseUrl} />
        } else {
            comp = ''
        }
    } else {
        comp = ''
    }
    return (
        <>
            <Row className="d-flex navbar-d justify-content-around w-auto d-flex align-items-center">
                <h2 className="text-light text-d">{props.admin ? "Okus Admin" : "Okus"}</h2>
                {comp}
                <div className="d-flex align-items-center">
                    <ProfileNav name={props.username} />
                </div>
            </Row>
            <div className="spacer-navbar" />
        </>
    );
}

export default Navbar;