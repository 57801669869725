import teacherImg from '../assets/images/teacher-desk.svg'
import studentImg from '../assets/images/books.svg'

export const getId = data => {
    let acum = []
    for (let n in data) {
        acum.push(data[n].id)
    }
    return acum
}
export const transformStudents = data => {
    // console.log(data)
    let acum = []
    for (let n in data) {
        acum.push({
            id: data[n].id,
            firstname: data[n].firstname,
            lastname: data[n].lastname,
            prm: data[n].prm,
            avatarUrl: data[n].img || studentImg
        })
    }
    return acum
}
export const transformHomeworks = data => {
    let acum = []
    for (let n in data) {
        acum.push({
            id: data[n].id,
            name: data[n].name,
            sectionId: data[n].school_grade_section_id,
            prm: data[n].prm,
            created: data[n].created,
            expire: data[n].expire,
            results: data[n].results,
            blocks: data[n].blocks
        })
    }
    return acum
}
export const teachersToStore = (teachers, gradeSectionsId) => {
    let result = teachers.map(({
        id,
        firstname,
        lastname,
        avatarUrl,
        created_at,
        updated_at
    }) => {
        return {
            id,
            firstname,
            lastname,
            gradeSectionsId,
            avatarUrl: avatarUrl || teacherImg,
            createAt: created_at,
            updateAt: updated_at
        }
    });
    return result
}

export default (data, teacherData) => {
    let sections = []
    let students = []
    let homeworks = []
    let teachers = []
    let schools = []
    for (let n in data) {
        const item = data[n]

        sections.push({
            id: item.id,
            grade: item.name,
            teacherId: teacherData.id,
            schoolsId: "",
            alumns: getId(item.alumns),
            homeworks: getId(item.homeworks),
            section: item.section
        })
        students.push({
            sectionId: item.id,
            students: transformStudents(item.alumns)
        })
        homeworks.push({
            sectionId: item.id,
            homeworks: transformHomeworks(item.homeworks)
        })
    }
    
    return {
        homeworks,
        teachers, // Actually this isn't necessary 
        students,
        sections,
        schools // This not have utility
    }
    // return data
}