import React, { useState } from 'react';
import '../assets/css/assignaments-table-item.css'
import 'react-circular-progressbar/dist/styles.css';
import { Collapse } from 'reactstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import icon from '../assets/images/hw-icon.svg'
import Card from '../containers/InfoCardAssignament'
import Preview from '../components/PreviewImageRecort'
import getDateObject from "../utils/getDateObject";
import Button from '../components/ButtonSmallSize'
import { useDispatch } from 'react-redux'
import studentImg from '../assets/images/books.svg'

// Comenta los expandibles l
// Conecta el resto
// Cinecta los expandibles
// Habremos terminado de enlazar esta view, luego toca la funcion para cargar datos al estado

const AssignamentsTableItem = props => {    
    // Calculate PRM in this homework
    const [students, setStudents] = useState(false);
    const [blocksC, setBlocksC] = useState(false);
    const dispatch = useDispatch()

    const date = getDateObject(props.data.expire)
    const active = new Date(props.data.expire).getTime() > new Date().getTime()
    const studentsNum = props.data.sections_alumns.length

    let goodResults = []
    let badResults = []
    let zeroValues = []

    let studentsSuccess = 0
    let prm = 0
    for (let i in props.data.results) {
        const item = props.data.results[i]
        const student = props.data.sections_alumns.find(l => l.id === item.student_id)
        if (item.completed || item.score !== 0) {
            ++studentsSuccess
            prm += item.score
            if (item.score >= 70) {
                goodResults.push({
                    ...item,
                    name: `${student.firstname} ${student.lastname}`,
                    section: props.section,
                    imageUrl: student.avatarUrl
                })
            } else {
                if (item.score !== 0) {
                    badResults.push({
                        ...item,
                        name: `${student.firstname} ${student.lastname}`,
                        section: props.section,
                        imageUrl: student.avatarUrl
                    })
                }
            }
        } else {
            if (item.score === 0) {
                zeroValues.push({
                    ...item,
                    name: `${student.firstname} ${student.lastname}`,
                    section: props.section,
                    imageUrl: student.avatarUrl
                })            
            }
        }
    }
    // Here
    let acum = 0
    let temp = 0
    for (let i in props.data.results) {
        const item = props.data.results[i]
        if (item.score !== 0) {
            acum += item.score
            ++temp
        }
    }
    const prm2 = parseInt( acum / temp || 1 )
    // Here

    let prmgr = 0
    for (let n in goodResults) {
        const item = goodResults[n]
        prmgr += item.score
    }
    prmgr = parseInt(prmgr / goodResults.length || 1)

    let prmbr = 0
    for (let n in badResults) {
        const item = badResults[n]
        prmbr += item.score
    }
    prmbr = parseInt(prmbr / badResults.length || 1)
    return(
        <div>
            <div className={`assignaments-table-item ${props.active ? '' : 'a-t-i-inactive'}`}>
                <div className="a-t-i-first">
                    <img src={icon} alt=""/>
                    <span>
                        {props.data.name}
                    </span>
                </div>
                <div className="a-t-i-second ">
                    <div className="d-flex flex-column auto-size  justify-content-center">
                        <span className="info-text-item">{date.weekDay}</span> 
                        <span className="info-text-item">{date.date}</span>
                    </div>
                    <div className="a-t-i-s-status auto-size " >
                        <div className={`a-t-i-s-circle ${active ? 'atisc-active' : ''}`}></div>
                        <span className="info-text-item">{active ? 'Activa' : 'Inactiva'}</span>
                    </div>
                    <span className="info-text-item">{prm2}%</span>
                </div>
                <div className="a-t-i-last d-flex align-items-center">
                    <div className="d-flex">
                        <CircularProgressbar value={parseInt(studentsSuccess/(studentsNum || 1) * 100)} />
                        <span>
                            {`${studentsSuccess}/${studentsNum}`}
                        </span>
                    </div>
                    <div className="d-flex flex-column align-items-center h-auto">
                        <Button isBool={blocksC} value="Bloques" click={() => {
                            setBlocksC(!blocksC)
                            setStudents(false)
                        }} />
                        <Button isBool={students} value="Notas" click={() => {
                            setStudents(!students)
                            setBlocksC(false)
                        }} />
                    </div>
                </div>
            </div>
            <Collapse isOpen={students} className="a-t-i-collapse">
                <Card mode emuleClose circle={{count: zeroValues.length}} title="No hicieron las tareas" data={zeroValues} />
                <Card mode prm={prmbr} emuleClose circle={{count: badResults.length}} title="Bajas calificaciones" data={badResults} />
                <Card mode prm={prmgr} emuleClose circle={{count: goodResults.length}} title="Buen rendimiento" data={goodResults} />
            </Collapse>
            <Collapse isOpen={blocksC} className="a-t-i-collapse">
                {
                    props.data.blocks.map((item, key) => {
                        const x = `http://okus-web.s3-website-us-east-1.amazonaws.com/blocks_preview/${item.block_id}.Preview.png`
                        let students = []
                        let acum = 0
                        let len = 0

                        for (let n in item.results) {
                            if (item.results[n].score) {
                                acum += item.results[n].score
                                ++len
                            }
                            const result = item.results[n]
                            const student = props.data.sections_alumns.find(l => l.id === result.student_id)
                            students.push({
                                ...result,
                                name: `${student.firstname} ${student.lastname}`,
                                section: props.section,
                                imageUrl: student.avatarUrl || studentImg
                            })
                        }

                        students.sort((a,b) => b.score-a.score)
                        const low = students.filter(item => item.score <= 69 && item.is_completed === true)
                        const none = students.filter(item => item.is_completed === false)

                        const actionsPreview = {
                            openLowResults: () => dispatch({
                                type: "MODAL_SWITCH",
                                payload: {
                                    title: "Resultados bajos",
                                    results: low,
                                    count: low.length,
                                    status: true
                                }
                            }),
                            openNoneResults: () => dispatch({
                                type: "MODAL_SWITCH",
                                payload: {
                                    title: "Sin completar",
                                    results: none,
                                    count: none.length,
                                    status: true
                                }
                            }),
                            openAllResults : () => dispatch({
                                type: "MODAL_SWITCH",
                                payload: {
                                    title: "Resultados",
                                    results: students,
                                    count: students.length,
                                    status: true
                                }
                            })
                        }
                        const prm = parseInt(acum / len || 1)
                        return <div key={key} > <Preview actions={actionsPreview} data={{ low, none, prm: prm }} key={key} src={x} /> </div>
                    })
                }
            </Collapse>
        </div>
    )
}

export default AssignamentsTableItem