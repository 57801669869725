import React from 'react'
import { sections_data as firebaseApi } from '../utils/api/firebase_section_api'
import toStore from '../utils/mysqlToStore'
import  mysqlApi from '../utils/api/mysql_api'
import firebaseToStore from '../utils/firebaseToStore'
import { get } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import useOnceData from "../hooks/useOnceData"
import useLoading from '../hooks/useLoading'
import { useHistory } from 'react-router-dom'
import '../assets/css/teachers-table.css'

const TeachersTable = props => {
    const deployData = useOnceData()
    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector(state => state.users.data)
    const loading = useLoading()

    return (
        <>
            <div className="teachers-table">
                <div className="t-t-item" >
                    <span onClick={() => {
                        const essential_key = props.data.id
                        // console.log(props.data)
                        
                        if (props.firebase) {
                            dispatch({
                                type: 'GEN_LOAD',
                                id: "teacher_table 27",
                                payload: true
                            })
                            firebaseApi(essential_key)
                                .then(async data => {
                                    const result = await firebaseToStore(data, essential_key)
                                    dispatch({
                                        type: "CLEAN_DATA_STATE"
                                    })
                                    dispatch({
                                        type: "RES",
                                        id: "teacher table",
                                        payload: result
                                    })
                                    dispatch({
                                        type: 'GEN_LOAD',
                                        id: "teacher table",
                                        payload: false
                                    })
                                    dispatch({
                                        type: 'SET_NAME',
                                        payload: props.data.firstname + " " + props.data.lastname
                                    })
                                    history.push('/admin/teacher/alumnos')
                                })
                                .catch(err => {
                                    dispatch({
                                        type: 'GEN_LOAD',
                                        id: "teacher_table 52",
                                        payload: false

                                    })
                                    console.log(err)
                                })
                        } else {
                            let teacher = {...props.data, teacher_id: props.data.id, user_role: "teacher"}
                            dispatch({
                                type: 'SET_NAME',
                                payload: `${teacher.firstname} ${teacher.lastname}`
                            })
                            loading(true)
                            history.push('/admin/teacher/alumnos')
                            deployData(teacher, teacher.teacher_id)   
                        }
                    }}>
                        {
                            props.data.firstname + " " + props.data.lastname
                        }
                    </span>
                </div>
            </div>
        </>
    )
}

export default TeachersTable