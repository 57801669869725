import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { withRouter }
import '../assets/css/section-tabs.css'

import mapGradeName from '../utils/mapGradeName'

class SectionTabs extends Component {
    mapGradeName = mapGradeName
    state = {
        permission: true
    }
    seter = payload => {
        this.props.dispatch({
            type: "SELECT_SECTION",
            id: "Section Tabs",
            payload
        });
    }
    componentDidUpdate() {
        if (this.state.permission) {
            this.setState({
                permission: false
            })
            this.props.seter(this.props.data[0])
        }
    }
    render() {
        const data = this.props.data.map((item, key) => {
            item.real_name = this.mapGradeName(item.grade) + item.section
            if (!key && !this.props.actualState.id) this.seter(item)
            return item
        }).sort((a,b) => a.real_name.localeCompare(b.real_name))
        const x = this.props.actualState ? this.props.actualState.real_name : "no section selected"
        // console.log(data)
        return (
            <>
                <div className="section-tabs">
                    {
                        data.map((item, key) => {
                            // console.log(this.props.actualState.real_name, item.real_name)

                            return <div key={key} onClick={() => this.seter(item)} className={`s-t-item ${(x === item.real_name)  ? 's-t-active' : ''}`}>
                                {
                                    `${item.real_name}`
                                }
                            </div>
                        })
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({ actualState: state.layout.data.section_selected })

export default connect(mapStateToProps)(SectionTabs);