import React from 'react'
import '../assets/css/modal-opa.css'

const ModalOpa = props => {
    return (
        <div className="modal-opa">
            {props.children}
        </div>
    )
}

export default ModalOpa