import React, { useState } from "react"
import { sections_data as firebaseApi } from '../utils/api/firebase_section_api'
import { useDispatch, useSelector } from 'react-redux'
import toStore from '../utils/mysqlToStore'
import firebaseToStore from '../utils/firebaseToStore'
import useLoading from './useLoading'
import { getValuesFromKeys, alumnWithPrmV2, agroupByKey, prmFromResults } from '../utils/utilsFunctions'

import getTeacher from "../api_methods/mysql_apiV2/getTeacher"
import getAllSectionsFromTeacher from "../api_methods/mysql_apiV2/getAllSectionsFromTeacher";
import estructureSection from '../api_methods/mysql_apiV2/estructureSection';
import useStrutureSections from '../hooks/useStrutureSections';
import mysqlToStore, { teachersToStore, getId } from '../utils/mysqlToStore';

const prmPerStudentMapper = results => {
    const prm = prmFromResults(results, true)
    const { student_id } = results[0]
    return {
        student_id,
        prm
    }
}

const alumnsWithPrm = (homeworks, students) => {
    const allResults = getValuesFromKeys("results", homeworks, true)
    const agroupByStudentResult = agroupByKey("student_id", allResults)
    const prmPerStudent = agroupByStudentResult.map(prmPerStudentMapper)

    const result = students.map(student => {
        let prmFound = prmPerStudent.find(item => student.id === item.student_id)
        if (!prmFound) {
            prmFound = {
                prm: 0
            }
        }
        return {
            ...student,
            prm: prmFound.prm
        } 
    })

    return result
}

const useOnceData = props => {
    // const [permission, setPermission] = useState(true);
    const dispatch = useDispatch()
    const loading = useLoading()

    const { getSectionsAndStructure } = useStrutureSections({ 
        getSections: getAllSectionsFromTeacher,
        structureSections: estructureSection
    });
    
    return async (user, essential_key) => {
        switch (user.user_role) {
            case "teacher":
            
                if (user.firebase) {
                    firebaseApi(essential_key)
                        .then(async data => {
                            const result = await firebaseToStore(data, essential_key)   
                            loading(false)
                            dispatch({
                                type: "CLEAN_DATA_STATE",
                                id: "app",
                            })
                            dispatch({
                                type: "RES",
                                id: "app",
                                payload: result
                            })
                        })
                        .catch(err => {
                            loading(false)
                            //console.log(err)
                        })
                } else {
                    try {
                        dispatch({
                            type: "CLEAN_DATA_STATE"
                        })
                        let { data } = await getTeacher(essential_key)
                        getSectionsAndStructure(section => {
                            section.alumns = alumnsWithPrm(section.homeworks, section.alumns)
                            const { sections, students, homeworks } = mysqlToStore([section], data)
    
                            dispatch({
                                type: "PUSH_SECTION",
                                payload: { sections, students, homeworks }
                            })
                            loading(false)
                            
                            return section
                        }, essential_key) 
    
                        dispatch({
                            type: "PUSH_TEACHER",
                            payload: [data]
                        })
                    } catch(e) {
                        dispatch({
                            type: "CLEAN_STATE"
                        })
                        console.error(e)
                    }
                }
                break;
            case "admin":

                break;
            case "coordinator":

                break;
            default:
                loading(false)
                console.log("default")
                break;
        }
    }
}

export default useOnceData