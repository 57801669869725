import { getBlockData, getTeacherData } from './firebaseUtils'
import studentImg from '../assets/images/books.svg'
import teacherImg from '../assets/images/teacher-desk.svg'

const getBlocksFromBG = async bg => {
    let result = []
    for (let i in bg) {
        const group = bg[i]
        for (let i in group.items) {
            const block = group.items[i]
            let acum = 0
            const results = block.assigned_to.map(result => {
                if (result) {
                    const data = result
                    acum += data.score
                    // console.log(data)
                    return {
                        complete: data.is_completed,
                        student_id: data.student_id,
                        score: data.score,
                        viewed: data.viewed,
                        time: data.time
                    }
                }
            })
            const block_det = await getBlockData(block.block_id)
            // console.log(block)
            const item = {
                descriptor: block.block_descriptor_id,
                block_id: block_det.front_id,
                quantity: block.quantity,
                retry: block.retry,
                results,
                prm: parseInt(acum / (block.assigned_to.length || 1))
            }
            result.push(item)
        }
    }

    return result
}
const getPrm = (array, key) => {
    let acount = 0
    for (let i in array) {
        const item = array[i]
        acount += item[key]
    }
    return parseInt(acount / (array.length || 1))
}
const getOnlyId = array => {
    return array.map(item => item.id)
}
const generateAlumnHwResult = (alumns, blocks) => {
    let result = []

    for (let i in alumns) {
        const alumn = alumns[i]
        let score = 0,
            acount = 0,
            viewed = false;
        for (let i in blocks) {
            const block = blocks[i]
            if (block) {
                // console.log(block)
                const result = block.results.find(item => item.student_id === alumn.id)
                if (result) {
                    if (result.is_completed) ++acount
                    if (result.viewed) viewed = true
                    score += result.score
                }
            }
        }
        result.push({
            completed: (acount === blocks.length),
            score: parseInt(score / (blocks.length || 1)),
            student_id: alumn.id,
            viewed
        })
    }

    return result
}
const calculatePrmFromHws = (alumnId, hws) => {
    let acount = 0
    for (let n in hws) {
        const hw = hws[n]
        const item = hw.results.find(item => item.student_id === alumnId)
        if (item) {
            acount += item.score
        }
    }
    return parseInt(acount / (hws.length || 1))
}
export default async (data, teacherId) => {
    console.log("FirebaseToStore has an error. Add the assignament date.")
    let homeworks = [],
        schools = [],
        sections = [],
        students = [],
        teachers = [];
    
    for (let n in data) {
        const section = data[n]
        const teacherData = await getTeacherData(teacherId)
        let studentsAcum = {
            sectionId: section.id,
            students: []
        }
        let homeworksAcum = {
            sectionId: section.id,
            homeworks: []
        }
        sections.push({
            grade: section.grade,
            alumns: getOnlyId(section.alumns),
            homeworks: getOnlyId(section.homeworks),
            id: section.id,
            schoolsId: '',
            section: section.section,
            teacherId: teacherId
        })
        teachers.push({
            avatarUrl: teacherImg,
            firstname: teacherData.name,
            lastname: teacherData.lastname,
            gradeSectionsId: getOnlyId(data),
            id: teacherId
        })

        for (let n in section.homeworks) {
            const hw = section.homeworks[n]
            const blocks = await getBlocksFromBG(hw.block_group)
            homeworksAcum.homeworks.push({
                id: hw.id,
                name: hw.name,
                expire: hw.exp_date,
                sectionId: section.id,
                prm: getPrm(blocks, 'prm'),
                results: generateAlumnHwResult(section.alumns, blocks),
                blocks
            })
        }
        for (let n in section.alumns) {
            const student = section.alumns[n]
            const prm = calculatePrmFromHws(student.id, homeworksAcum.homeworks)
            studentsAcum.students.push({
                avatarUrl: studentImg, 
                firstname: student.name,
                lastname: student.lastname,
                id: student.id,
                prm  
            })
        }
        homeworks.push(homeworksAcum)
        students.push(studentsAcum)
    }

    return {
        homeworks,
        schools,
        sections,
        students,
        teachers
    }
}