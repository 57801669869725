import React from 'react'
import { useDispatch } from 'react-redux'

export default () => {
    const dispatch = useDispatch()
    const loading = (bool, id = undefined) => dispatch({
      type: 'GEN_LOAD',
      id: "useLoading 8",
      id,
      payload: bool
    })
    // console.log("se llamo")
    return loading
}