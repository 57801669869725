import React from 'react'
import '../assets/css/hw-item.css'

const HwItem = (props) => {
    const time = new Date(props.data.expire)
    const compare = (time >= new Date())
    // Here
    let acum = 0
    let temp = 0
    for (let i in props.data.results) {
        const item = props.data.results[i]
        if (item.score !== 0) {
            acum += item.score
            ++temp
        }
    }
    const prm = parseInt( acum / temp || 1 )
    // Here
    return (
        <div className="i-t-header">
            <div className="i-t-h-header">
                <span className="i-t-h-subtitle">
                    {`${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`}
                </span>
                <div className="i-t-h-h-item">
                    <div className={`ml-2 t-t-circle ${compare ? 't-t-c-active' : 't-t-c-inactive'}`}></div>
                    <span>{compare ? 'Activa' : 'Inactiva'}</span>
                </div>
            </div>
            <span className="i-t-h-title">
                {props.data.name || "Titulo por default"}
            </span>
            <div className="d-flex justify-content-between mr-2">

                <span className="i-t-h-subtitle">
                    {`PRM: ${prm}`}
                </span>
            </div>
        </div>
    )
}

export default HwItem