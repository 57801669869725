import React from 'react';
import '../assets/css/layout-list-schools.css'

import Item from '../components/SchoolItem'

const LayoutList = props => {
    const data = props.data ? props.data : []
    return (
        <>
            <div className="layout-list">
                <div className="l-l-header">

                </div>
                <div className="l-l-content">
                    {
                        data.map((item, key) => {
                            return <Item data={item} key={key} />
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default LayoutList;