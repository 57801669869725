import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { Container, Row } from 'reactstrap'
import Navbar from '../containers/Navbar'
import { existSection } from '../utils/utilsFunctions'
import '../assets/css/coordinator.css'

import TeachersListView from '../containers/TeachersListView'
import AlumnView from '../containers/AlumnView'
import HomeworkView from '../containers/HomeworkView'
import BackArrow from '../components/BackArrow'
import Layout from '../components/MidViewLayout'

const Coordinator = props => {
    const teachers = useSelector( state => state.teachers.values )
    const users = useSelector( state => state.users)
    const homeworks = useSelector( state => state.homeworks.values )
    const students = useSelector( state => state.students.values )
    const actual_section = useSelector( state => state.layout.data.section_selected )
    let sections = useSelector( state => state.sections.values )

    sections = sections.map(section => {
        const alumns = students.find( item => item.sectionId === section.id )
        const hws = homeworks.find( item => item.sectionId === section.id )

        section.alumns = alumns.students
        section.homeworks = hws.homeworks
        return section
    })
    
    const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    const isMainPos = location.pathname === "/coordinador/profesores"

    // Next line verify if the actual position is main. When it's main, 
    // the navbar dont have permission for show his buttons

    const verify = !isMainPos ? sections.length ? true : false : false

    const clean = () => dispatch({type: "CLEAN_DATA_STATE"})

    const arrowFunc = () => {
        history.push("/coordinador/profesores")
        clean()
    }    
    return (
        <>
            {/* <Navbar username={users.data.username} buttons={data.length ? ['alumnos', 'tareas'] : []} /> */}
            <Navbar username={users.data.username} baseUrl="/coordinador/profesores" buttons={verify ? ['alumnos', 'tareas'] : []} />

            <div className="dashboard-cont">
                <Container fluid>
                    <Layout>
                        <Row>
                            <Switch>
                                <Route exact path="/coordinador/" render={() => <Redirect to="/coordinador/profesores" /> } />
                                <Route exact path="/coordinador/profesores" render={() => <TeachersListView teachers={props.data} /> } />

                                <Route exact path="/coordinador/profesores/alumnos" render={() => <> <BackArrow click={arrowFunc} /> <AlumnView teachers={teachers} data={sections} /> </> } />
                                <Route exact path="/coordinador/profesores/tareas" render={() => <> <BackArrow click={arrowFunc} /> <HomeworkView teachers={teachers} data={sections} /> </>} />
                            </Switch>
                        </Row>
                    </Layout>
                </Container>
            </div>
        </>
    )
}

export default Coordinator;