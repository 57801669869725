import { existValueInItems } from '../utils/utilsFunctions'

export default (state, action) => {
    const { sections: newSections, homeworks, students } = action.payload
    const { sections: sectionsInState } = state

    let sectionsToPush = []
    let hwsToPush = []
    let alumnsToPush = []
    
    for (let newSec of newSections) {
        const exist = existValueInItems("id", newSec.id, sectionsInState.values)
        if (!exist) sectionsToPush.push(newSec)
    }

    for (let secToPush of sectionsToPush) {
        const hwsToStore = homeworks.filter((item) => item.sectionId === secToPush.id)
        const studentsToStore = students.filter((item) => item.sectionId === secToPush.id)

        hwsToPush.push(...hwsToStore)
        alumnsToPush.push(...studentsToStore)
    }

    return {
        ...state, 
        homeworks: {
            ...state.homeworks,
            values: [...state.homeworks.values, ...hwsToPush]
        },
        students: {
            ...state.students,
            values: [...state.students.values, ...alumnsToPush]
        },
        sections: { 
            ...state.sections, 
            values: [...state.sections.values, ...sectionsToPush] 
        }
    }
}