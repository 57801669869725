import React from 'react'
import '../assets/css/assignmanet-table-filter.css'
import triangle from '../assets/images/triangle-bottom.svg'

const AssignamentTableFilter = props => {
    return (
        <>
            <div className="a-t-filter d-flex justify-content-center align-items-center">
                <span>
                    {props.name}
                </span>
                <img src={triangle} alt="" className={props.status ? 'rotate' : ''} onClick={props.action} />
            </div>
        </>
    )
}

export default AssignamentTableFilter