import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { existSection } from '../utils/utilsFunctions'
import '../assets/css/admin.css';

import Layout from '../components/MidViewLayout'
import { Container, Row } from 'reactstrap';
import { connect } from 'react-redux'
import { get } from 'axios'
import { withRouter } from 'react-router'

import { Switch, Route } from 'react-router-dom'
import Navbar from '../containers/Navbar'
import Back from '../components/AdminBackArrow'

import AlumnView from '../containers/AlumnView'
import HomeworkView from '../containers/HomeworkView'

import TableSchool from '../containers/TableSchools'
import { getSchoolInfo, teachersStructure } from '../utils/firebaseUtils'
import useLoading from '../hooks/useLoading'

class Admin extends React.Component {
    constructor(props) {
        super()
        this.state = {
            schools: []
        }
    }
    loading = (bool) => {
        this.props.dispatch({
            type: 'GEN_LOAD',
            id: "admin 32",
            payload: bool	
        })
    }
    componentDidMount() {
        const list = this.props.data.payload

        for (let i in list) {
            const item = list[i]
            if (item.school_id) {
                if (item.firebase) {
                    getSchoolInfo(item.school_id)
                        .then(result => {
                            const teachers = teachersStructure(result.teachers)
                            let acum = this.state.schools
                            acum.push({
                                name: result.name,
                                teachers,
                                firebase: true
                            })
                            this.setState({
                                schools: acum
                            })
                            this.loading(false)
                        })
                        .catch(err => {
                            this.loading(false)
                        })
                } else {
                    get(`/api/schools/school/${item.school_id}`)
                        .then(result => {
                            const data = result.data.data
                            let acum = this.state.schools
                            acum.push({
                                name: data.name,
                                teachers: data.teachers,
                                firebase: false
                            })
                            this.setState({
                                schools: acum
                            })
                            this.loading(false)
                        })
                        .catch(err => {
                            this.loading(false)
                        })
                }
            } else {
                console.error("Este item tiene el school_id en undefined", item)
            }
        }        
    }
    render() {
        const homeworks = this.props.homeworks
        const students = this.props.students
        let sections = this.props.sections

        sections = sections.map(section => {
            const alumns = students.find( item => item.sectionId === section.id )
            const hws = homeworks.find( item => item.sectionId === section.id )
    
            section.alumns = alumns.students
            section.homeworks = hws.homeworks
            return section
        })
        const data = sections
        // const firstSection = sections[0]
        // if (firstSection) {
        //     const exist = existSection(firstSection.id, sections)
        //     if (this.props.actual_section.id === "" && exist) {
        //         this.props.dispatch({
        //             type: "SELECT_SECTION",
        //             payload: firstSection
        //         });
        //     }
        // }
        return (
            <>
                <div className="admin">
                    <Navbar username={this.props.data.username} baseUrl="/admin/teacher" buttons={data.length ? ['alumnos', 'tareas'] : []} />
                    <div className="dashboard-cont">
                        <Container fluid>
                            <Layout>
                                <Row>
                                    <Switch>
                                        <Route exact path="/admin/" render={() => <TableSchool data={this.state.schools} />} />
                                        <Route path="/admin/teacher/alumnos" render={() => <><Back /> <AlumnView data={data} /></>} /> } />
                                        <Route path="/admin/teacher/tareas" render={() => <><Back /> <HomeworkView data={data} /></> } />
                                    </Switch>
                                </Row>
                            </Layout>
                        </Container>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({ 
    data: state.users.data,
    teacher: state.users.data,
    layout: state.layout,
    homeworks: state.homeworks.values,
    students: state.students.values,
    sections: state.sections.values,
    actual_section: state.layout.data.section_selected
})
export default withRouter(connect(mapStateToProps)(Admin));