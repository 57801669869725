import React, { Component } from 'react';
import '../assets/css/login.css';
import { withRouter } from 'react-router-dom'
import { Container, Row } from 'reactstrap'
import { post, get } from 'axios'
import { connect } from 'react-redux'
import Keypress from 'react-keypress'
import Inputs from '../components/LoginInputs'
import Button from '../components/ButtonLogin'

class Login extends Component {
	constructor(props) {
		super(props)
		this.state={
			user: "",
			pass: "",
			allow: true,
			shake: false
		}
	}
	handleLogin = () => {
		const { user , pass } = this.state
		if(user !== "" && pass !== "") {
			this.props.success({ username: user, password: pass })
		} else {
			this.shaker()
		}
	}
	userHandlerChange = e => {
		this.setState({
			user: e.target.value
		})
	}
	passHandlerChange = e => {
		this.setState({
			pass: e.target.value
		})
	}
	shaker = () => {
		this.setState({
			shake: true
		})
		setTimeout(() => {
			this.setState({
				shake: false
			})
		}, 700)
	}
    render() {
		return(
            <>
				<div className="login-container" onKeyPress={Keypress("enter", this.handleLogin)} >
					<Container>
						<Row className="d-flex justify-content-center">
							<h1 className="l-c-title">Iniciar sesión</h1>
						</Row>
						<Row className="d-flex justify-content-center mt-4">
							<Inputs 
								user 
								place="Usuario o Correo"
								change={this.userHandlerChange} 
								value={this.state.user}
								// press={Keypress("enter", this.handleLogin)}
							/>
						</Row>
						<Row className="d-flex justify-content-center mt-4 mb-4">
							<Inputs
								shake={this.state.shake || this.props.shake}
								ocult
								place="Contraseña"
								change={this.passHandlerChange} 
								value={this.state.pass}
								// press={Keypress("enter", this.handleLogin)}
							/>
						</Row>
						<Row className="d-flex justify-content-center mt-5">
							<Button charging={this.props.charging} click={this.handleLogin}/>
						</Row>
					</Container>
				</div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
	return {}
}

export default withRouter(connect(mapStateToProps)(Login))