import React from 'react'
import '../assets/css/button-small-size.css'

const ButtonSmallSize = props => {

    return (
        <>
            <button onClick={props.click} className={`button-small-size ${props.isBool ? 'onpress-bss' : ''}`}>
                {props.value}
            </button>
        </>
    )
}

export default ButtonSmallSize