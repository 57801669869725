import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Route, Switch } from 'react-router';
import { Row, Container } from 'reactstrap';
import Logo from './../assets/images/logotipo.png';
import Login from '../containers/Login';
import { post, get } from 'axios'
import useLoading from '../hooks/useLoading'
import useOnceData from "../hooks/useOnceData"

import '../assets/css/authView.css';

const AuthView = props => {
    const deployData = useOnceData()
	const dispatch = useDispatch()
	const routes = useSelector(state => state.layout.data.routes)
	const auth = useSelector(state => state.layout.data.auth)
	const history = useHistory()
	const [charging, setCharging] = useState(false)
	const [shake, setShake] = useState(false)
	const loading = useLoading()
	const handleLogin = data => {
		setCharging(true)
		post('/api/users/', {
			...data
		})
			.then(result => {
				if (!result.data) {
					//  Url in a const
					post('https://production.okus-domain-2.net/oauth/token', {
						grant_type: 'password',
						client_id: 2,
						client_secret: '0SliAxQgOVZoTK4Lx7NAKL6pqPfd4fi3qJCeLorv',
						scope: '*',
						username: data.username,
						password: data.password,
						// Mac addres is invalid
						mac_address: 201909051302,
						provider: 'teachers',
						teachers: 'teachers'
					}, {
						headers: {
							"Content-Type": "application/json",
							"Accept": "application/json"
						}
					})
						.then(result => {
							get('https://production.okus-domain-2.net/api/v1/teacher', {
								headers: {
									"Authorization": `Bearer ${result.data.access_token}`
								}
							})
								.then(teacher => {
									setCharging(false)
									const data = {
										id: teacher.data.id,
										essential_key: teacher.data.id,
										token: result.data.access_token,
										payload: [],
										firebase: false,
										user_role: 'teacher',
										username: teacher.data.firstname
									}
									dispatch({
										type: "LOGIN_USER",
										payload: data
									})
									deployData(data, data.essential_key)
									loading(true)
									history.push('/dashboard')
									props.events.loginSuccess()
								})
								.catch(err => {
									setCharging(false)
									console.log(err)
								})
						})
						.catch(err => {
							setShake(true)
							setTimeout(() => {
								setShake(false)
							}, 900)
							setCharging(false)
							//console.log(err)
						})		
				} else {
					setCharging(false)
					const data = {
						id: result.data.id,
						essential_key: result.data.essential_key,
						token: null,
						payload: result.data.payload,
						firebase: result.data.firebase,
						user_role: result.data.type,
						username: result.data.name || result.data.username
					}
					dispatch({
						type: "LOGIN_USER",
						payload: data
					})
					deployData(data, data.essential_key)

					const type = result.data.type
					if (type !== "coordinator") {
						loading(true)
					}
					history.push(routes[type].main)
					props.events.loginSuccess()
				}
			})
			.catch(err => {
				setCharging(false)
				console.log(err)
			})
		// Handle login
	}


	if (auth) {
		history.push('/dashboard')
	}
	return(
		<Container className="authView" fluid>
			<Row className="row-logo justify-content-center">
				<div className="space-logo">
					<img src={Logo} alt="" className="logo"/>
				</div>
			</Row>
			<Row className="d-flex justify-content-center">
				<Switch>
					<Route exact path="/auth" render={() => <Login shake={shake} charging={charging} success={handleLogin} />} />
				</Switch>
			</Row>
		</Container>
	)
}

export default AuthView