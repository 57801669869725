import React from 'react'
import '../assets/css/admin-back-arrow.css'
import arrow from '../assets/images/left-arrow.svg'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

const AdminBackArrow = props => {
    const backLocation = () => {
        props.dispatch({
            type: "CLEAN_DATA_STATE"
        });
        props.history.push('/admin')
    }
    return (
        <>
            <img onClick={backLocation} className="arrow-bottom" src={arrow} alt="" />
        </>
    )
}

const mapStateToProps = state => ({})

export default withRouter(connect(mapStateToProps)(AdminBackArrow))