export default {
    "homeworks": {
        "values": [],
        "updateAt": ""
    },
    "teachers": {
        "values": [],
        "appId": "",
        "updateAt": ""
    },
    "students": {
        "values": [],
        "appId": "",
        "updateAt": ""
    },
    "sections": {
        "values": [],
        "appId": "",
        "updateAt": ""        
    },
    "schools": {
        "values": [],
        "appId": "",
        "updateAt": ""
    },
    "layout": {
        "data": {
            "loading": false,
            "user_type": {
                "admin": false,
                "teacher": false,
                "coordinator": false
            },
            "modals": {
                "homework_view": {
                    "open": false,
                    "data": {
                        "circle": {
                            "count": 0
                        }
                    }
                }
            },
            "routes": {
                "teacher": {
                    "main": "/dashboard"
                },
                "admin": {
                    "main": "/admin"
                },
                "coordinator": {
                    "main": "/coordinador"
                }
            },
            "section_selected": {
                "id": "",
                "name": "",
                "section": "",
                "homeworks": [],
                "real_name": "",
                "section": ""
            },
            "navigation_button": false,
            "auth": false
        },
        "appId": "",
        "updateAt": ""
    },
    "users": {
        "data": {
            "id": "",
            "token": "",
            "essential_key": "",
            "payload": [],
            "firebase": false,
            "user_role": "",
            "username": "",
            "createAt": ""
        },
        "appId": "",
        "updateAt": ""
    }
}
