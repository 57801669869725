import firebase from "firebase/app";
import 'firebase/database';
import { classromsVerify, parserJsonFirebase, getOnlyType, getAllTeachers, agroupItemsWithoutId, getBlockInfo, getBlockInfoOffline } from '../firebaseUtils'

const firebaseConfig = {
    apiKey: "AIzaSyDb_Clj76bf54aB9NfwqnNyQ30av4FOU7I",
    authDomain: "okus-3b383.firebaseapp.com",
    databaseURL: "https://okus-3b383.firebaseio.com",
    projectId: "okus-3b383",
    storageBucket: "okus-3b383.appspot.com",
    messagingSenderId: "198182413228",
    appId: "1:198182413228:web:18a761da67744a30b6d009"
};

firebase.initializeApp(firebaseConfig);

export const getTeacherInfo = async teacherId => {
    const snapTeacher = await firebase.database().ref(`profile/${teacherId}`).once('value')
    const value = snapTeacher.val()
    const sections = classromsVerify(value.section_id)
    return {
        ...value,
        section_id: sections
    }
}
export const getSectionsFromTeacher = async teacherIdId => {
    return {

    }
}
// Todo refactor nested for loops
export const sections_data = async (teacherId) => {
    // Get Teacher data by him ID, and the sections data
    const snapTeacher = await firebase.database().ref(`profile/${teacherId}`).once('value')
    const snapSHI = await firebase.database().ref(`student_homework_info`).once('value')
    const studentHomeworkInfo = snapSHI.val()
    // console.log(studentHomeworkInfo)
    let idFromSections = classromsVerify(snapTeacher.val().section_id)
    let sections = []

    // Estructure the sections data, 
    for (var sectionIndex in idFromSections) {
        const sectionSnap = await firebase.database().ref(`classrooms/${idFromSections[sectionIndex]}`).once('value')
        // const alumnsSnap = await firebase.database().ref(`profile`).orderByChild(`section_id/${idFromSections[sectionIndex]}`).equalTo(true).ref.orderByChild('role').equalTo('student').once('value')
        const alumnsSnap = await firebase.database().ref(`profile`).orderByChild('role').equalTo('student').ref.orderByChild(`section_id/${idFromSections[sectionIndex]}`).equalTo(true).once('value')
        const homeworksSnap = await firebase.database().ref(`homework`).orderByChild(`classroom/`).equalTo(idFromSections[sectionIndex]).once('value')
        
        
        let alumns = getOnlyType("student", parserJsonFirebase(alumnsSnap.val()))
        let homeworks = parserJsonFirebase(homeworksSnap.val())
        let section = sectionSnap.val()

        // Iterator into homeworks
        for (var h in homeworks) {
            let hw = homeworks[h]

            // parsed items into HW object
            hw.assigned_to = parserJsonFirebase(hw.assigned_to)
            hw.block_group = parserJsonFirebase(hw.block_group)
            
            // Iterator into blocks group for estructurate it
            for (var b in hw.block_group) {
                let parsed = agroupItemsWithoutId(hw.block_group[b])

                for (var p in parsed.items) {
                    let block = parsed.items[p]
                    block.assigned_to = []
                    for (var a in alumns) {
                        const data = getBlockInfoOffline(alumns[a].id, hw.id, parsed.id, block.block_id, studentHomeworkInfo )
                        if (data) {
                            data.student_id = alumns[a].id
                            block.assigned_to.push(data)
                        }
                    }
                    parsed.items[p] = block
                }
                hw.block_group[b] = parsed
            }
            homeworks[h] = hw
        }
        section.id = idFromSections[sectionIndex]

        sections.push({
            ...section,
            homeworks,
            alumns
        })
    }
    return sections
}

// Listeners
export const getAlumnsFromSections = (sectionId, callback) => {
    firebase.database().ref(`profile`).orderByChild(`section_id/${sectionId}`).equalTo(true).on('value', callback)
}
export const getSectionData = (sectionId, callback) => {
    firebase.database().ref(`classrooms/${sectionId}`).on('value', callback)
}
export const getHomeworksFromSection = (sectionId, studentHomeworkInfo, alumns, callback) => {
    const homeworksSnap = firebase.database().ref(`homework`).orderByChild(`classroom/`).equalTo(sectionId).once('value', snap => {
        let result = snap.val()
        for (var h in result) {
            let hw = result[h]
            hw.assigned_to = parserJsonFirebase(hw.assigned_to)
            hw.block_group = parserJsonFirebase(hw.block_group)
            for (var b in hw.block_group) {
                let parsed = agroupItemsWithoutId(hw.block_group[b])

                for (var p in parsed.items) {
                    let block = parsed.items[p]
                    block.assigned_to = []
                    for (var a in alumns) {
                        const data = getBlockInfoOffline(alumns[a].id, hw.id, parsed.id, block.block_id, studentHomeworkInfo )
                        if (data) {
                            data.student_id = alumns[a].id
                            block.assigned_to.push(data)
                        }
                    }
                    parsed.items[p] = block
                }
                hw.block_group[b] = parsed
            }
            result[h] = hw
        }
        callback(snap.val())
    })
}
export const getHomeworkInfo = callback => {
    firebase.database().ref(`student_homework_info`).once('value', callback)
}
export const listenerCaller = (hws, callback) => {
    for (let i in hws) {
        const hw = hws[i]
        for (let n in hw.results) {
            const result = hw.results[n]
            firebase.database().ref(`student_homework_info/${result.student_id}/${hw.id}/block_group_info`).on('child_changed', snap => {
                callback(snap, {
                    student_id: result.student_id,
                    homework_id: hw.id,
                    section_id: hw.sectionId
                })
            })
        }
    }
}