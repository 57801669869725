import React from 'react';
import PropTypes from 'prop-types'

const radius = 175;
const diameter = Math.round(Math.PI * radius * 2);
const getOffset = (val = 0) => Math.round((100 - Math.min(val, 100)) / 100 * diameter);

const Circle = props => {
    const defaultData = {
        progress: 0,
        animate: true,
        animationDuration: '1s',
        showPercentage: true,
        showPercentageSymbol: true,
        progressColor: 'rgb(76, 154, 255)',
        bgColor: '#ecedf0',
        textColor: '#6b778c',
        size: '100',
        lineWidth: '25',
        percentSpacing: 10,
        textStyle: { font: 'bold 4rem Helvetica, Arial, sans-serif' },
        text: true
    }
    
    const data = {...defaultData, ...props}
    const strokeDashoffset = getOffset(data.progress);
    const transition = data.animate ? `stroke-dashoffset ${data.animationDuration} ease-out` : undefined;
    const strokeLinecap = data.roundedStroke ? 'round' : 'butt';
    const svgSize = data.responsive ? '250px' : data.size;
    
    return(
        <svg width={svgSize} height={svgSize} viewBox="-25 -25 400 400">
            <circle 
                stroke={data.bgColor} 
                cx="175" 
                cy="175" 
                r="175" 
                strokeWidth={data.lineWidth} 
                fill="none"
            />
            <circle stroke={data.progressColor} transform="rotate(-90 175 175)" cx="175" cy="175" r="175" strokeDasharray="1100" strokeWidth={data.lineWidth} strokeDashoffset="1100" strokeLinecap={strokeLinecap} fill="none" style={{ strokeDashoffset, transition }} onTransitionEnd={data.onAnimationEnd}/>
            {/* {props.children} */}
            {
                data.text ?
                    <text style={data.textStyle} fill={data.textColor} x={radius} y={radius} textAnchor="middle" dominantBaseline="central">
                        {data.progress}{data.showPercentageSymbol && <tspan dx={data.percentSpacing}>%</tspan>}
                    </text>
                :
                    null
            }
        </svg>
    )
}

Circle.propTypes = {
    progress: PropTypes.number,
    animate: PropTypes.bool,
    animationDuration: PropTypes.string,
    showPercentage: PropTypes.bool,
    showPercentageSymbol: PropTypes.bool,
    progressColor: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    size: PropTypes.string,
    lineWidth: PropTypes.string,
    percentSpacing: PropTypes.number,
    textStyle: PropTypes.object,
    text: PropTypes.bool
}

export default Circle