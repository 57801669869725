import React from 'react'
import '../assets/css/info-alumn-item.css'

const InfoAlumnItem = props => {
    return (
        <>
            <div className="info-alumn-item">
                <div className="i-a-i-item">
                    <img src={props.imageUrl} alt="" className="i-a-i-i-img" />
                    <h1 className="i-a-i-i-title">
                        {props.name}
                    </h1>
                </div>
                <div className="i-a-i-item justify-content-around">
                    <span className="ml-2 i-a-i-i-span">
                        {props.section || ''}
                    </span>
                    <span className="i-a-i-i-prm ">
                        {
                            props.prm ?
                                parseInt(props.prm) + "%"
                            :
                                (props.score || 0)
                        }
                    </span>
                </div>
            </div>
        </>
    )
}

export default InfoAlumnItem