export default (n) => {
    switch (n) {
        case '1ro':
            return '1';
        case '2do':
            return '2';
        case '3ro':
            return '3';
        case '4to':
            return '4';
        case '5to':
            return '5';  
        case '6to':
            return '6'; 
        case '1ro Secundaria':
            return '7'; 
        case '2do Secundaria':
            return '8';
        case '3ro Secundaria':
            return '9';
        default :
            return n + " ";
    }
}