import React from 'react'
import '../assets/css/mid-view-layout.css'

const MidViewLayout = props => {
    return(
        <div className="mid-size-l">
            {props.children}
        </div>
    )
}

export default MidViewLayout