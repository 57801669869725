import firebase from "firebase/app";
import 'firebase/database';

export const classromsVerify = (obj) => {
    let result = []
    const keys = Object.keys(obj)
    for (let i in keys) {
        if (obj[keys[i]]) {
            result.push(keys[i])
        }
    }
    return result
}
export const teachersStructure = array => {
    return array.map(item => {
        let newItem = {}
        newItem.firstname = item.name
        newItem.lastname = item.lastname
        newItem.id = item.id
        return newItem
    })
}
export const parserJsonFirebase = (obj) => {
    const keys = Object.keys(obj)
    const values = Object.values(obj)
    let result = []

    for (let i in values) {
        result.push({
            ...values[i],
            id: keys[i]
        })
    }

    return result
}
export const agroupItemsWithoutId = (obj) => {
    const keys = Object.keys(obj)
    const values = Object.values(obj)
    let items = []

    for (let i in keys) {
        if (keys[i] !== 'id') {
            items.push(values[i])
        }
    }

    return {
        items,
        id: obj.id
    }
}
export const getBlockData = async (blockId) => {
    const blockSnap = await firebase.database().ref(`blocks/${blockId}/`).once('value')
    return blockSnap.val()
}
export const getTeacherData = async (teacherId) => {
    const teacherSnap = await firebase.database().ref(`profile/${teacherId}/`).once('value')
    return teacherSnap.val()
}
export const getBlockInfo = async (studentId, homeworkId, groupId, blockDescId) => {
    const alumnsSnap = await firebase.database().ref(`student_homework_info/${studentId}/${homeworkId}/block_group_info/${groupId}/block_info/`).once('value')
    return alumnsSnap.val()
}
export const uniqueTeachers = data => {
    let acum = []
    let results = []
    for (let n in data) {
        const element = data[n]
        const result = acum.find(item => item === element.id)
        if (!result) {
            acum.push(element.id)
            results.push(element)
        }
    }
    return results
}
export const getSchoolInfo = async (schoolId) => {
    let school = {
        name: '',
        teachers: []
    }
    const schoolsSnap = await firebase.database().ref(`school/${schoolId}/name`).once('value')
    school.name = await schoolsSnap.val()
    const sectionsSnap = await firebase.database().ref(`classrooms/`).orderByChild('school_id').equalTo(schoolId).once('value')
    const sections = Object.keys(sectionsSnap.val())
    for (let i in sections) {
        const sec = sections[i]
        const userSnap = await firebase.database().ref('profile/').orderByChild(`section_id/${sec}`).equalTo(true).ref
        const teachersSnap = await userSnap.orderByChild('role').equalTo('teacher').once('value')
        const data = teachersSnap.val()
        school.teachers = [...school.teachers, ...parserJsonFirebase(data)]
    }
    school.teachers = uniqueTeachers(school.teachers)
    return school
}
export const getBlockInfoOffline = (studentId = "", homeworkId = "", groupId = "", blockId, data = {}) => {  
    const studentInfo = data[studentId]
    if (studentInfo) {
        const homework = studentInfo[homeworkId]
        if (homework) {
            const bg = homework.block_group_info[groupId]
            if (bg) {
                const parsed = parserJsonFirebase(bg.block_info).find(item => item.block_descriptor_id === blockId)
                return parsed
            } else {
                // console.error("Block group not exist. BlockGroupId: " + groupId + ". From HomeworkID: " + homeworkId + ". From AlumnID: " + studentId)
            }
        } else {
            // console.error("Homework not exist. HomeworkID: " + homeworkId + ". From AlumnID: " + studentId)
        }
    } else {
        // console.error("Student not exist. StudentID: " + studentId)
    }
}
export const resultParser = (data, student_id) => {
    return {
        complete: data.is_completed,
        student_id,
        score: data.score,
        viewed: data.viewed,
        time: data.time
    }
}
export const getOnlyType = (string, data) => {
    let result = []
    for (let n in data) {
        const item = data[n]
        if (item.role === string) result.push(item)
    }
    return result
}