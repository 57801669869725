import { get } from "axios"

export default async (teacherId, cancelToken) => {
    try {
        const { data } = await get(`/api/v2/sections/homework/${teacherId}`, {
            cancelToken
        });
        return data
    } catch(e) {
        console.error(e)
        return null
    }
}