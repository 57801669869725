import React from 'react';
import Navbar from '../containers/Navbar'
import { Container, Row } from 'reactstrap'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { existSection } from '../utils/utilsFunctions'

import '../assets/css/dashboard.css'

// Utils

import Layout from '../components/MidViewLayout'
import AlumnView from '../containers/AlumnView'
import HomeworkView from '../containers/HomeworkView'
import AlumnProfileView from '../containers/AlumnProfileView'

const getFromValues = (obj, key) => {
    return obj[key]
}

const Dashboard = props => {
    const teacher = useSelector( state => state.users.data )
    const layout = useSelector( state => state.layout )
    const homeworks = useSelector(  state => state.homeworks.values )
    const students = useSelector(  state => state.students.values )
    let sections = useSelector( state => state.sections.values )

    sections = sections.map(section => {
        const alumns = students.find( item => item.sectionId === section.id )
        const hws = homeworks.find( item => item.sectionId === section.id )

        section.alumns = alumns.students
        section.homeworks = hws.homeworks
        return section
    })
    
    const data = sections
    const baseUrl = layout.data.routes.teacher.main
    const firstSection = sections[0]
    return (
        <>
            <Navbar buttons={["Alumnos", "Tareas", /* "Perfiles" disabled */]} baseUrl={baseUrl} username={`${teacher.username}`} />
            <div className="dashboard-cont">
                <Container fluid>
                    <Layout>
                        <Row>
                            <Switch>
                                <Route exact path="/dashboard/">
                                    <Redirect to="/dashboard/alumnos" />
                                </Route>
                                <Route  path="/dashboard/alumnos">
                                    <AlumnView data={data} />
                                </Route>
                                <Route path="/dashboard/tareas">
                                    <HomeworkView data={data} />
                                </Route>
                                <Route path="/dashboard/perfiles">
                                    <Redirect to="/dashboard/alumnos" />
                                    {/* <AlumnProfileView data={data} /> */}
                                </Route>

                                {/* <Route path="/dashboard/test" render={() => <AlumnProfileView /> } /> */}
                                {/* <Route exact path="/dashboard/alumnos/profile" render={() => props.redirect ? <AlumnProfileView /> : <Redirect to="/dashboard/alumnos" />} /> */}
                            </Switch>
                        </Row>
                    </Layout>
                </Container>
            </div>
        </>
    )
}

export default Dashboard