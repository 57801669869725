import React, { useState } from 'react'
import '../assets/css/homework-view.css'
import { Container, Row } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import Card from '../containers/InfoCardAssignament'
import CardModal from '../containers/InfoCardAssignamentModal'
import { Modal } from 'reactstrap';

import Table from '../components/AssignamentsTable'
import Sections from '../components/SectionTabs'

const HomeworkView = props => {
    const [data, setData] = useState({
        name: "",
        section: "",
        homeworks: [],
        real_name: "",
        section: ""
    })
    const teacherName = useSelector(state => state.layout.teacher_name)
    const modal = useSelector(state => state.layout)
    const role = useSelector(state => state.users.data.user_role)
    const actualSection = useSelector(state => state.layout.data.section_selected)
    const dispatch = useDispatch()

    const seter = (d) => {
        if (d) setData(d)
    }
    const setModal = () => {
        dispatch({
            type: "MODAL_SWITCH",
            payload: {
                count: 0,
                results: [],
                title: '',
                status: false
            }
        })
    }
    const open = modal.data.modals.homework_view.open
    const modalData = modal.data.modals.homework_view.data
    if (actualSection.id !== data.id) {
        setData(actualSection)
    }
    // console.log("se actualizo hw", data)

    // const teacher = props.teachers.find(item => item.id === props.data[0].teacherId)
    return (
        <>
            <Container className="container-d-views" fluid>
                <Row className="d-flex justify-content-between flex-column hv-header">
                    <div className="hv-title">
                        {
                            (role === "teacher") ?
                                <h1>Tus asignaciones</h1>
                            :
                                <h1 style={{fontSize: 34}}>{`Alumnos de ${teacherName}`}</h1>
                        }
                    </div>
                    <Sections 
                        seter={seter} 
                        actual={data.real_name} 
                        data={props.data}
                    />
                </Row>
                <Row>
                    <Table 
                        data={data} 
                        filters={{
                            entrega: () => {},
                            estatus: () => {},
                            prm: () => {}
                        }}
                    />
                </Row>
            </Container>
            <Modal isOpen={open} toggle={setModal} className="results-modal">
                <CardModal count={modalData.count} closeAction={setModal} title={modalData.title} data={modalData.results} />
            </Modal> 
        </>
    )
}

export default HomeworkView