import React from 'react'
import link from '../assets/images/link.svg';
import '../assets/css/preview-image-recort.css'

const PreviewImage = props => {
    // const prm = props.data.prm
    return (
        <div className="d-flex flex-column align-items-center">
            <div className="p-i-r-item">
                <img src={props.src} alt="" className="p-i-r-i-img" />
            </div>
            <div className="p-i-r-data">
                <div className="p-i-r-d-item border-right-divide press-hov d-flex flex-column justify-content-between" onClick={props.actions.openNoneResults}>
                    {/* <img src={link} alt="" onClick={props.actions.openNoneResults} className="mt-2 icon-link" /> */}
                    <span className="p-i-r-d-i-span span-val d-flex flex-column mt-3">{props.data.none.length}</span>
                    <div className="p-i-r-d-i-header pb-4">
                        <span className="little-title">Sin completar</span>
                    </div>
                </div>
                <div className="p-i-r-d-item d-flex flex-column press-hov justify-content-between" onClick={props.actions.openLowResults}>
                    {/* <img src={link} alt="" onClick={props.actions.openLowResults} className="mt-2 icon-link" /> */}
                    <span className="p-i-r-d-i-span span-val mt-3">{props.data.low.length}</span>
                    <div className="p-i-r-d-i-header pb-3">
                        <span className="little-title">Resultados bajos</span>
                    </div>
                </div>
                <div className="p-i-r-d-item press-hov border-left-divide d-flex flex-column justify-content-between" onClick={props.actions.openAllResults}>
                    <span className="p-i-r-d-i-span span-val mt-3">{props.data.prm}%</span>
                    <div className="p-i-r-d-i-header pb-4">
                        <span className="little-title ">PRM</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewImage