import React, { Component } from 'react'
import { Popover } from 'reactstrap';

import '../assets/css/ProfileNav.css'

import {chevronDown} from 'react-icons-kit/ionicons/chevronDown'
import { Icon } from 'react-icons-kit'
import teacherImg from '../assets/images/teacher-desk.svg'
import MenuUser from '../components/MenuUser'

class ProfileNav extends Component {
    state = {
        rotated: false
    }
    handlerPop = () => {
        this.setState({
            rotated: !this.state.rotated
        })
    }
    render() {
        return (
            <>
                <div className="profile-d  d-flex align-items-center">
                    <img className="" src={teacherImg} alt="" />
                    <span className="">{this.props.name}</span>
                    <Popover
                        placement="bottom"
                        target="pop"
                        isOpen={this.state.rotated}
                        toggle={this.handlerPop}
                        className="menu-profile"
                    >
                        <MenuUser admin={this.props.admin} />
                    </Popover>
                    <Icon 
                        id="pop"
                        className={this.state.rotated ? "rotated" : ""} 
                        style={{ color: "white", opacity: "0.7", cursor: "pointer" }} 
                        icon={chevronDown}
                        onClick={() => {
                            this.setState({
                                rotated: !this.state.rotated
                            })
                        }}
                    />
                </div>
            </>
        );
    }
}
 
export default ProfileNav;