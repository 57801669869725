import React, { useState, useEffect } from "react"

const useStrutureSections = ({ getSections, structureSections }, teacherId, ) => {
    const [sections, setSections] = useState([]);

    const getSectionsHandler = async (teacherId) => {
        try {
            const { data } = await getSections(teacherId)
            setSections(data)
            return data
        } catch(e) {
            console.error(e)
            return [];
        }
    }

    useEffect(() => {
        if (teacherId) {
            getSectionsHandler(teacherId)
        }
    }, []);


    return {
        getSectionsAndStructure: async (parser = data => data, teacherId) => {
            try {
                const { data: dataSections } = await getSections(teacherId)
                for (let section of dataSections) {
                    structureSections(section)
                        .then(structurateSections => {
                            parser(structurateSections)
                        })
                        .catch(e => {
                            console.error(e)
                        })
                }
            } catch(e) {
                console.log(e)
                return []
            }
        },
        getStructuredData: async (parser = data => data) => {
            try {
                let result = []
                for (let section of sections) {
                    const { data } = await structureSections(section)
                    result.push(parser(data))
                }
                return result
            } catch(e) {
                console.log(e)
                return []
            }
        }
    }
}

export default useStrutureSections