import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { CircularProgressbar } from 'react-circular-progressbar';
import '../assets/css/alumn-item.css';

import eye from '../assets/images/eye.svg'
import studentsIcon from '../assets/images/books.svg'
// const values = props.data.results.map(item => {
//     return parseInt(item.results.reduce((acum,a) => acum+a) / item.results.length)
// });

const AlumnItem = props => {
    const dispatch = useDispatch()
    let acum = 0
    const values = []
    values.map(item => {
        if (item > 0) {
            ++acum
        }
    })
    const setAlumn = () => {
        dispatch({
            type: "SET_ALUMN_DETAILS",
            payload: {
                id: props.data.id,
                name: props.data.firstname,
                prm: props.data.prm,
                email: props.data.email,
                section_id: props.data.school_grade_section_id
            }
        });
        props.history.push('/dashboard/alumnos/profile')
    }
    const full = parseInt(props.percent.success/props.percent.total * 100 || 1)
    return(
        <>
            <div className="alumn-item">
                <div className="a-i-profile">
                    <img src={props.data.avatarUrl} alt="" />
                    <div className="a-i-p-name">
                        <span>{props.data.firstname || ''}</span>
                        <span>{props.data.lastname || ''}</span>
                    </div>
                </div>
                <div className="a-i-percent">
                    {
                        `${props.data.prm || 0}%`
                    }
                </div>
                <div className="a-i-status">
                    <CircularProgressbar value={full} />
                    <span>{`${props.percent.success}/${props.percent.total}`}</span>
                </div>
            </div>
        </>
    )
}

export default withRouter(AlumnItem);