import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import SmarthRedirect from '../components/RedirectComponent'
import '../assets/css/fontFace.css'
import '../assets/css/main.css'


import Loading from '../components/ModalOpa'
import Dashboard from '../pages/Dashboard';
import AuthView from '../pages/AuthView';
import Admin from '../pages/Admin';
import Coordinator from '../pages/Coordinator'

const App = props => {
    const dispatch = useDispatch()
    const modal = useSelector(store => store.layout.data.loading)

    const items = useSelector(state => state.layout.data.user_type)
    const user = useSelector(state => state.users.data)
    const routes = useSelector(state => state.layout.data.routes)
    const auth = useSelector(state => state.layout.data.auth)
    const hws = useSelector(state => state.homeworks.values)
    const section = useSelector(state => state.layout.data.section_selected);

    if (!section) {
        dispatch({
            type: "CLEAN_STATE"
        })
    }
    let activeHomeworks = []
    for (let i in hws) {
        const item = hws[i]
        for (let n in item.homeworks) {
            const hw = item.homeworks[n]
            if (new Date(hw.expire) >= new Date()) activeHomeworks.push(hw)
        }
    }

    const loginSuccess = () => {
    }
    return (
        <>
            {
                modal ?
                    <Loading>
                        <div className=" loading-content"></div>
                    </Loading>
                :
                    ''
            }
            <Route exact path="/" >
                <Redirect to="/auth" />
            </Route>
            <Route path="/auth">
                <AuthView 
                    events={{
                        loginSuccess
                    }}
                />
            </Route>
            <Route path="/coordinador" >
                <SmarthRedirect
                    auth={auth}
                    type={items}
                    routes={routes} 
                    permission={items.coordinator}
                >
                    <Coordinator data={user.payload} />
                </SmarthRedirect>
            </Route>
            <Route path="/dashboard" >
                <SmarthRedirect
                    auth={auth}
                    type={items}
                    routes={routes}
                    permission={items.teacher}
                >
                    <Dashboard />
                </SmarthRedirect>                    
            </Route>
            <Route path="/admin">
                <SmarthRedirect
                    auth={auth}
                    type={items}
                    routes={routes}
                    permission={items.admin}
                >
                    <Admin data={user.payload} />
                </SmarthRedirect>
            </Route>
        </>
    );
}


export default App;