import getAlumnsFromSections from "./getAlumnsFromSections"
import getAllHwFromSections from './getAllHwFromSections'

export default async (section) => {
    const { data: alumnsFromThis } = await getAlumnsFromSections(section.id);
    const { data: homeworksFromThis } = await getAllHwFromSections(section.id)

    section.alumns = alumnsFromThis
    section.homeworks = homeworksFromThis

    return section
}