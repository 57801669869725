import React, { useState, useEffect } from 'react'
import '../assets/css/assignaments-table.css'

import Filters from '../components/AssignamentTableFilter'
import Item from '../components/AssignamentsTableItem'

const AssignamentsTable = props => {
    const [prm, setPrm] = useState(null);
    const [deadline, setDeadline] = useState(null);
    const [status, setStatus] = useState(null);
    const [data, setData] = useState([]);

    const deafultFilters = () => {
        setPrm(null);
        setDeadline(null);
        setStatus(null);
    }
    let homeworks = props.data.homeworks

    if (deadline === true) {
        homeworks = homeworks.sort((a,b) => {
            const expireA = new Date(a.expire)
            const expireB = new Date(b.expire)
            return expireB-expireA
        })
    } else if (deadline === false) {
        homeworks = homeworks.sort((a,b) => {
            const expireA = new Date(a.expire)
            const expireB = new Date(b.expire)
            return expireA-expireB
        })
    }

    if (prm === true) {
        homeworks = homeworks.sort((a,b) => {
            return a.prm-b.prm
        }) 
    } else if (prm === false) {
        homeworks = homeworks.sort((a,b) => {
            return b.prm-a.prm
        }) 
    }

    if (status === true) {
        homeworks = homeworks.sort((a,b) => {
            const itemA = (new Date(a.expire) <= new Date())
            const itemB = (new Date(b.expire) <= new Date())

            return (itemA === itemB) ? 0 : (itemA) ? 1 : -1
        }) 
    } else if (status === false) {
        homeworks = homeworks.sort((a,b) => {
            const itemA = (new Date(a.expire) <= new Date())
            const itemB = (new Date(b.expire) <= new Date())
            
            return (itemB === itemA) ? 0 : (itemA) ? -1 : 1
        })
    }
    return (
        <>
            <div className="assignament-table">
                <div className="a-t-header a-t-i--d">
                    <div className="at-h-first">
                    </div>
                    <div className="at-h-second a-t-i--d">
                        <Filters 
                            status={deadline}
                            name="Entrega" 
                            action={() => {
                                deafultFilters();
                                setDeadline(!deadline);
                            }} 
                        />
                        <Filters 
                            status={status}
                            name="Status" 
                            action={() => {
                                deafultFilters();
                                setStatus(!status);
                            }} 
                        />
                        <Filters 
                            status={prm}
                            name="PRM" 
                            action={() => {
                                deafultFilters();
                                setPrm(!prm);
                            }} 
                        />
                    </div>
                    <div className="at-h-last a-t-i--de">
                        <div></div>
                    </div>
                </div>
                <div className="a-t-content">
                    {
                        homeworks.map((item, key) => {
                            item.sections_alumns = props.data.alumns
                            return <Item 
                                section={props.data.grade}
                                key={key} 
                                data={item} 
                            />
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default AssignamentsTable