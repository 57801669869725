import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Popover } from 'reactstrap';
import '../assets/css/burguer-button.css'
import menuImg from '../assets/images/menu-button.svg'
import MenuPopover from '../containers/MenuPopover'
import Button from '../components/PopoverMenuButton'

const BurguerButton = props => {
    const [open, setOpen] = useState(false)
    const history = useHistory()
    const handlerSwitch = () => {
        setOpen(!open)
        console.log("test")
    }
    return (
        <>
            <button onClick={handlerSwitch} className="burguer-button" id="Popover1">
                <img alt="" src={menuImg} />
            </button>
            <Popover
                placement="bottom"
                target="Popover1"
                isOpen={open}
                toggle={handlerSwitch}
            >
                <MenuPopover >
                    <Button click={() => history.push(`${props.baseUrl}/alumnos`)} title={"Alumnos"} />
                    <Button click={() => history.push(`${props.baseUrl}/tareas`)} title={"Tareas"} />
                </MenuPopover>
            </Popover>
        </>
    )
}

export default BurguerButton